.school-list {
    width: 180px;
    max-height: 73.8vh;
    text-align: bottom;
    font-size: 12px;
    font-family: 'Neue-Montreal-medium';
    display: block;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    line-height: 2vh;
}
.school-list::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}


.school:hover {
    opacity:50;
}

.degree-title {
    font-size: 14px;
    font-family: 'Neue-Montreal-bold'
}

.school-name {
    font-family: 'Neue-Montreal-medium';
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 0;
}
.school-when {
    font-family: 'Neue-Montreal-regular';
    margin-top: 0;
    margin-bottom: 0;
    font-size: 10px;
}

.school-list li {
    list-style-type: none;
    margin: 0;
    transition: margin 0.3s ease;
    font-family: 'Neue-Montreal-medium';
    font-size: 20px;
}


.awards-list {
    padding-left:10px;
    margin-bottom: 20px;
    line-height: 1.5vh;
}

.award {
    /* width: fit-content;
    padding-left:5px;
    padding-right:5px; */
    font-family: 'Neue-Montreal-regular';
    font-size: 8px;
    /* border: 0.5px solid white;
    border-radius: 5px;
    margin-right: 0; */
}