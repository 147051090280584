

.about-content {
    width: 180px;
    max-height: 75vh;
    text-align: bottom;
    font-size: 12px;
    font-family: 'Neue-Montreal-medium';
    display: block;
    overflow: auto;
}

.about-content .content {
    width: 100%;
    height: 100%;
}

.content a {
    font-size: 12px;
    font-family: 'Neue-Montreal-bold'
}
