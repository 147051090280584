body {
    background-color: rgb(0, 0, 0);
    color: white;
    height:95vh;
    width:95vw;
}

.page-container {
    position: fixed;
    border: 0.5px solid rgba(255, 255, 255, 0.8);
    left: 20px;
    right: 20px;
    top: 20px;
    bottom: 20px;
    z-index: 3;
    font-family: 'Neue-Montreal-light';
}

.header {
    position: fixed;
    left: 40px;
    top: 40px;
}


.header-title {
    font-size: 30px;
    margin: 0px;
}
.header-subtitle {
    font-size: 12px;
    margin-top: 5px;
    font-family: 'Neue-Montreal-italic';
}



.page-content {
    position: fixed;
    text-align: left;
    bottom: 40px;
    right: 40px;
    z-index:2;
}


#particles-js {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -10;
    top: 0;
    left: 0
}

.frame {
    position: fixed;
    border: 20px solid rgba(0, 0, 0);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.frame-thin {
    position: fixed;
    border: 0.5px solid rgba(255, 255, 255);
    left: 20px;
    right: 20px;
    top: 20px;
    bottom: 20px;
    z-index: 1;
}